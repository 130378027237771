
import styled from '@emotion/styled'
import { breakpoints, colours } from '../../utils/styles'

export const StyledFilterButton = styled.button`
    
    &.active{
        background: ${colours.pink};
        transform: rotate(-2deg);
        transition: 0.5s;
        color: ${colours.black};
    }
`

export const FilterTitle = styled.h3`
    background: ${colours.black};
    color: ${colours.lightblue};
    margin: 0;
    padding: 0.6rem; 0
`
export const FilterContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
    @media (min-width: ${breakpoints.s}px){
        flex-direction: row;
    }
    background: transparent;
    ${StyledFilterButton}{
        margin: 0;
        margin-top: -1rem;
        margin-right: 1rem;
    }
    ${StyledFilterButton}:last-of-type {
        margin-right: 0;
    }
    
`