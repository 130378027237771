import React from 'react'

import SEO from '~/components/seo'
import ProductGrid from '~/components/ProductGrid'
import StoreFilter from '~/components/StoreFilter'
import FunkyShapeCom from '~/components/FunkyShape'
import { Container, GridItem, TwoColumnGrid } from '../utils/styles'

const IndexPage = () => {
  const [tag, setTag] = React.useState('map', 'London', 'bigmips')
  return (
    <>
      <SEO title="Lucie Conoley | The Mips Showcase" description="A showcase of the most important places" keywords={[`Mips`, `maps`, `prints`, `MipMaps`]} />
      <TwoColumnGrid>
        <GridItem>
          {/* <StoreFilter setTag={setTag} /> */}
        </GridItem>

        <GridItem>
          <FunkyShapeCom fill={'#DC85B7'}>
            <h2 className="is-1 title">...It all started with a map</h2>
            <blockquote>J.R.R. Tolkien</blockquote>
          </FunkyShapeCom>
        </GridItem>
      </TwoColumnGrid>
      <ProductGrid tag={tag}>
      </ProductGrid>
    </>
  )
}

export default IndexPage
